import { SupportPlatformOs } from '@axieinfinity/hub-services'

export * from './build'
export * from './crypto'
export * from './date'
export * from './game'
export * from './helper'
export * from './number'
export * from './string'

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms))

export const isDev = import.meta.env.DEV || !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
export const isUsingDarwin = window.bridge?.platform === 'darwin'
export const isUsingMacOs = isUsingDarwin
export const isUsingWin32 = window.bridge?.platform === 'win32'
export const isUsingWindows = isUsingWin32

export const isBrowsingOnWeb = typeof window.bridge === 'undefined' || !('invoke' in window.bridge.kataru)
export const isBrowsingOnDesktop = !isBrowsingOnWeb

export const osMapping: Partial<Record<NodeJS.Platform, SupportPlatformOs>> = {
	'win32': 'windows',
	'darwin': 'macos',
}
export const userOs = window.bridge?.platform ? osMapping[window.bridge.platform] ?? 'unknown' : 'unknown'
