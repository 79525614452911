import { ChangedCookie, COOKIE_NAME } from '@axieinfinity/kukki'
import { useEffect } from 'react'
import { match, P } from 'ts-pattern'

import { renewTokens } from '#/core/browser/request'
import { cookie, ObservableCookie } from '#/core/cookie'
import { queryClient } from '#/core/react-query'

import { userProfileQueryKey } from '../query'

const refetchProfile = () => queryClient.invalidateQueries({ queryKey: userProfileQueryKey })

export const useAuthListener = () => {
  useEffect(() => {
    const { accessToken, refreshToken } = cookie.getAll()
    match({ accessToken, refreshToken })
      .with({ accessToken: P.string }, () => refetchProfile())
      .with({ refreshToken: P.string }, ({ refreshToken }) => renewTokens(refreshToken))
      .with({ refreshToken: P.nullish }, () => refetchProfile())
      .exhaustive()

    const handleCookieChanges = (cookie: ChangedCookie<keyof ObservableCookie>) => {
      if (cookie.name === COOKIE_NAME.ACCESS_TOKEN) {
        refetchProfile()
      }
    }

    const unsubscribe = cookie.subscribe(handleCookieChanges)

    return unsubscribe
  }, [])
}
