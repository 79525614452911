import { COOKIE_NAME } from '@axieinfinity/kukki'

import { cookie, defaultCookieOptions, oneYearExpiryDate } from '../cookie'
import { persistStorage } from '../local-storage'
import { logger } from '../logger'
import { services } from '../services'

export function clearTokens() {
  cookie.remove(COOKIE_NAME.REFRESH_TOKEN, defaultCookieOptions)
  cookie.remove(COOKIE_NAME.ACCESS_TOKEN, defaultCookieOptions)
}

export async function renewTokens(refreshToken = cookie.get(COOKIE_NAME.REFRESH_TOKEN)) {
  if (!refreshToken) return (clearTokens(), undefined)

  try {
    const { response, error, result } = await services.request('post /v2/auth/token/refresh', { body: { refreshToken } })
    if (response.status !== 200 || error) return (clearTokens(), undefined)
    else {
      const isSession = persistStorage.getIsSession()
      cookie.set(COOKIE_NAME.ACCESS_TOKEN, result.accessToken, {
        ...defaultCookieOptions,
        isSession,
        expires: new Date(result.accessTokenExpiresAt),
        maxAge: result.accessTokenExpiresIn,
      })

      cookie.set(COOKIE_NAME.REFRESH_TOKEN, result.refreshToken, {
        ...defaultCookieOptions,
        isSession,
        expires: oneYearExpiryDate(),
      })

      return result
    }
  } catch (error) {
    logger.error(error, { error }, 'renewTokens')
  }
}
